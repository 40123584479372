'use client';

import React from 'react';

import { useSearchContext } from '../../../data/context/searchContext';

import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from '../DrawerNavigation.module.css';

const SearchButton = () => {
    const { setShowOverlay } = useSearchContext();
    const openSearch = () => {
        setShowOverlay(true);
    };

    return (
        <Button
            variant="none"
            size="none"
            className={`_js-draw-nav-search ${styles.button}`}
            title="Open search bar"
            aria-label="Open search bar"
            onClick={openSearch}
        >
            Search
            <Icon icon="search-light" alt="search icon" />
        </Button>
    );
};

export default SearchButton;
