import React from 'react';

import SearchEmpty from '../SearchEmpty/SearchEmpty';
import SearchPopularSearches from '../SearchPopularSearches/SearchPopularSearches';
import SearchPopularConditions from '../SearchPopularConditions/SearchPopularConditions';
import PopularTreatments from '../TreatmentSuggestions/PopularTreatments/PopularTreatments';
import RecentlyViewedTreatments from '../TreatmentSuggestions/RecentlyViewedTreatments/RecentlyViewedTreatments';
import OrderAgainTreatments from '../TreatmentSuggestions/OrderAgainTreatments/OrderAgainTreatments';

type PopularTreatmentsProps = React.ComponentProps<typeof PopularTreatments>;

interface Props {
    searchTerm: string;
    popularTreatments: PopularTreatmentsProps['treatments']
}

const NoSearchResults = ({ searchTerm, popularTreatments }: Props) => (
    <div className="space-y-150 md:space-y-200 lg:space-y-300">
        <SearchEmpty searchTerm={searchTerm} />
        <SearchPopularSearches />
        <OrderAgainTreatments />
        <RecentlyViewedTreatments />
        {popularTreatments.length ? <PopularTreatments treatments={popularTreatments} /> : null}
        <SearchPopularConditions />
    </div>
);

export default NoSearchResults;
