import dispatchGoogleAnalyticsEvent, { gaEvents } from '../../../../analytics/google/dispatchGoogleAnalyticsEvent';
import { AnalyticsCommand } from '../interfaces';

export interface SelectSortCommandProps {
    event: gaEvents;
    list: string;
    sortSelection: string;
}

export interface SelectSortGAProps {
    list: string;
    sort_selection: string;
}

export class SelectSortCommand implements AnalyticsCommand {
    execute({ event, list, sortSelection }: SelectSortCommandProps) {
        const data: SelectSortGAProps = {
            list,
            sort_selection: sortSelection,
        };
        dispatchGoogleAnalyticsEvent(event, data);
    }
}
