import React from 'react';

import Card from '../../../_ui/_blocks/Card/Card';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import Link from '../../../NextJs/Link/Link';
import Icon from  '../../../_ui/_blocks/Icon/Icon';

import styles from './ConditionResults.module.css';

interface ConditionResultsProps {
    /** Conditions object array */
    conditions:
        | {
              condition: string;
              condition_url: string;
              condition_description: string;
          }[]
        | undefined;
    /** Limit the number shown in this result set */
    limit: number | undefined;
    count: number | undefined;
}

/**
 * Condition Results
 * Does what it says on the tin, displays a list of results.
 */
const ConditionResults = ({ conditions, limit, count }: ConditionResultsProps) => (
    <ul data-testid="condition-search-results" className="space-y-100">
        {conditions
            ? conditions.slice(0, limit).map((condition) => (
                <Card as="li" className="relative" key={condition.condition} variant="bordered" disableBoxShadow>
                    <article className="flex gap-100 justify-between items-center">
                        <div className="space-y-100">
                            <Typography as="h4" typeset="heading" size="100">
                                {condition.condition}
                            </Typography>
                            <Typography  size="090" color="quiet">
                                {count} Treatments
                            </Typography>
                        </div>
                            <Icon icon="chevron-right-secondary" alt="chevron icon pointing right" size="small" />
                        <Link
                            className={styles.link}
                            href={`/${condition.condition_url}`}
                            title={`Click to learn about ${condition.condition}`}
                        >
                            Click to view {condition.condition}
                        </Link>
                    </article>
                </Card>
            ))
            : null}
    </ul>
);

export default ConditionResults;
