'use client';

import { useRef } from 'react';
import useSWR from 'swr';

import { useAuthContext } from '../../../../../data/context/authContext';
import TrackViewedTreatments from '../../../../../lib/TrackViewedTreatments';
import { fetcher } from '../../../../../lib/fetch/fetcher';
import { RedisTreatment } from '../../../../../lib/redis/types/RedisTreatment';

/**
 * Hook to get the recently viewed treatments.
 * @see /src/app/api/treatment-cache/route.ts
 */
const useRecentlyViewedTreatments = () => {
    const { isLoggedIn, storybook } = useAuthContext();
    const ids = useRef(TrackViewedTreatments.getIds());

    // Should the request be made?
    const shouldMakeTreatmentRequest = !!(isLoggedIn && ids.current.length) || storybook;

    // Gets the recommended treatments from our Next API.
    const request = shouldMakeTreatmentRequest ? async () => {
        // Only import mock data if we are using this hook in Storybook.
        if (storybook) {
            // TODO: Reimplement this later, data needs updating to match new RedisTreatment interface.
            // return (await import('../../mock/RecentlyViewedTreatments.json')).default;
            return [];
        }

        const response = await fetcher(`/api/treatment-cache?ids=${ids.current.join(',')}`, {
            method: 'GET',
            cache: 'no-cache',
        });

        return (await response.json()).data.treatments as RedisTreatment[];
    } : null;

    // Get the recently viewed treatments using useQuery.
    const { data, isLoading } = useSWR(
        'useRecentlyViewedTreatments',
        request,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnMount: true,
        }
    );

    return {
        isLoading,
        treatments: data || [],
    };
};

export default useRecentlyViewedTreatments;
