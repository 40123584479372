'use client';

import React from 'react';
import clx from 'classnames';

import { RedisTreatment } from '../../../lib/redis/types/RedisTreatment';
import { ProductClickCommand, ProductClickCommandProps } from '../../../modules/sortAndFilter/patterns/commands/googleAnalytics';
import { gaEvents } from '../../../modules/analytics/google/dispatchGoogleAnalyticsEvent';
import { useAuthContext } from '../../../data/context/authContext';

import Card from '../../_ui/_blocks/Card/Card';
import TreatmentCardPrice from './TreatmentCardPrice/TreatmentCardPrice';
import StarRating from '../../_ui/_blocks/StarRating/StarRating';
import Tag from '../../_ui/_blocks/Tag/Tag';
import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Typography from '../../_ui/_blocks/Typography/Typography';
import ImageWithFallback from '../../NextJs/ImageWithFallback/ImageWithFallback';
import Link from '../../NextJs/Link/Link';

import styles from './TreatmentCard.module.css';

export enum Tags {
    bestSeller = 'Best seller',
    specialOffer = 'Special offer',
    bundlePack = 'Bundle pack',
    newProduct = 'New product',
    multiBuyOffer = 'Multi-buy offer',
    newPackaging = 'New packaging',
    sale = 'Sale',
}

export interface Props {
    treatment: RedisTreatment;
    link?: string;
    buttonText?: string;
    onClick?: () => void;
    className?: string;
    disableBoxShadow?: boolean;
}

/**
 * Runs every time someone clicks on a treatment card.
 */
const triggerGoogleAnalytics = (treatment: RedisTreatment, isLoggedIn: boolean) => {
    const command = new ProductClickCommand();

    const data: ProductClickCommandProps = {
        event: gaEvents.ProductClick,
        ecommerce: {
            click: {
                actionField: {
                    list: window.location.pathname, // URL path
                },
                products: [
                    {
                        name: treatment.title,
                        id: treatment.treatmentId,
                        category: treatment.condition.primaryConditionTitle,
                        variant: treatment.title!,
                        dimension2: isLoggedIn ? 'Logged in' : 'Not Logged in',
                        dimension3: treatment.treatmentType,
                    },
                ],
            },
        },
    };

    command.execute(data);
}

/**
 * Takes treatment data and displays it as a product card. The main data it should receive is treatment data
 * from the Redis cache but manual data could also be passed in as needed so long as it follows the same
 * structure as the RedisTreatment interface.
 */
const TreatmentCard = ({ treatment, link, buttonText, onClick, className, disableBoxShadow }: Props) => {
    const { isLoggedIn } = useAuthContext();

    const isHighlighted = treatment.card.tag && treatment.card.highlighted;
    const cardClassNames = clx(className, styles.card, isHighlighted ? styles[treatment.card.tag!] : undefined);

    const handleAnalyticsClick = () => triggerGoogleAnalytics(treatment, isLoggedIn);

    return (
        <Card
            variant="bordered"
            padding="none"
            className={cardClassNames}
            onClick={handleAnalyticsClick}
            disableBoxShadow={disableBoxShadow}
        >
            <header className={styles.header}>
                <div className={styles.tagWrapper}>
                    {treatment.card.tag && treatment.anyVariantInStock ? (
                        <Tag size="medium" className={styles.tag} color={treatment.card.tag}>
                            {Tags[treatment.card.tag]}
                        </Tag>
                    ) : null}
                    {treatment.anyVariantInStock === false
                        ? <Tag size="medium" color="error" className={styles.tag}>Out Of Stock</Tag>
                        : null}
                </div>
                <ImageWithFallback
                    fallback="/images/logo-dark.svg"
                    src={treatment.card.image}
                    className={styles.image}
                    alt={treatment.title}
                    width={160}
                    height={160}
                />
                <Typography
                    as="h3"
                    typeset="subheading"
                    size="100"
                    className="mt-075 @[215px]:mt-100"
                >
                    {treatment.card.title}
                </Typography>
            </header>
            <div className={styles.main}>
                {treatment.priceFrom
                    ? <TreatmentCardPrice type={treatment.card.type} price={treatment.priceFrom} />
                    : <div />}
                <div className="flex gap-050 items-end min-h-075 mt-025">
                    {treatment.selectedReview.averageRating
                        ? <StarRating className={styles.starRating} rating={treatment.selectedReview.averageRating} />
                        : null}
                    {treatment.selectedReview.numRatings ? (
                        <Typography size="070" color="quiet" lineHeight="none">
                            {`${treatment.selectedReview.numRatings} review${treatment.selectedReview.numRatings > 1 ? 's' : ''}`}
                        </Typography>
                    ) : null}
                </div>
                {buttonText ? (
                    <Button
                        className={`${styles.button} ${treatment.anyVariantInStock === false ? 'bg-decorators-subtle' : null}`}
                        size="small"
                        onClick={onClick}
                        title={buttonText}
                    >
                        {buttonText}
                    </Button>
                ) : null}
            </div>
            {link ? (
                <Link className={styles.link} href={link} title={`View ${treatment.title}`} prefetch="hover">
                    <span className="opacity-0">{treatment.title}</span>
                </Link>
            ) : null}
        </Card>
    );
};

export default TreatmentCard;
