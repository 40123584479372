import React from 'react';

import { RedisTreatment } from '../../../../lib/redis/types/RedisTreatment';

import TreatmentCard from '../../../Treatment/TreatmentCard/TreatmentCard';

interface Props {
    treatments: RedisTreatment[];
    className?: string;
}

const TreatmentCardGrid = ({ treatments, className }: Props) => (
    <ul className={`grid grid-cols-2 gap-075 md:grid-cols-3 md:gap-100 ${className || ''}`}>
        {treatments.map((treatment) => (
            <li key={treatment._id}>
                <TreatmentCard
                    treatment={treatment}
                    link={treatment.relativeUrl}
                    className="h-full"
                    disableBoxShadow
                />
            </li>
        ))}
    </ul>
);

export default TreatmentCardGrid;
