export const searchPopularConditions = [
    {
        name: 'Weight Loss',
        url: '/weight-loss',
    },
    {
        name: 'Pain',
        url: '/pain',
    },
    {
        name: 'Hair Loss',
        url: '/hair-loss',
    },
    {
        name: 'Menopause (HRT)',
        url: '/menopause-hrt',
    },
    {
        name: 'Erectile Dysfunction',
        url: '/erectile-dysfunction-ed',
    },
    {
        name: 'Acne',
        url: '/acne',
    },
    {
        name: 'Eczema & Dermatitis',
        url: '/eczema-dermatitis',
    },
    {
        name: 'Hay Fever & Allergy',
        url: '/hay-fever',
    },
];
