import React from 'react';

import { searchPopularSearches } from './config/searchPopularSearches.config';
import { useSearchContext } from '../../../data/context/searchContext';

import Typography from '../../_ui/_blocks/Typography/Typography';
import TagCloud from '../../TagCloud/TagCloud';

type PopularSearches = (typeof searchPopularSearches)[number];

const SearchPopularSearches = () => {
    const { setSearchTerm } = useSearchContext();

    const handleTagClick = (tag: PopularSearches) => {
        setSearchTerm(tag.term);
    };

    return (
        <div className="space-y-100 text-center">
            <Typography as="h4" typeset="subtitle" size="100" weight="600">
                Popular Searches
            </Typography>
            <div className="max-w-[26.25rem] w-full mx-auto">
                <TagCloud color="regular" tags={searchPopularSearches} onClick={handleTagClick} />
            </div>
        </div>
    );
};

export default SearchPopularSearches;
