'use client';

import React, { Fragment, useCallback, useEffect, useRef } from 'react';
import { CSSTransition } from 'react-transition-group';
import useSWR from 'swr';

import { useSearchContext } from '../../../data/context/searchContext';
import useOnNavigate from '../../../hooks/useOnNavigate';
import { scrollToTop } from '../../../helpers/scrollTo';
import useWindowSizeCheck from '../../../hooks/useWindowSizeCheck';
import { getPopularTreatments } from '../../../app/(site)/search-results/query';

import Loading from '../../_ui/_blocks/Loading/Loading';
import TreatmentResults from '../SearchResultLists/TreatmentResults/TreatmentResults';
import ConditionResults from '../SearchResultLists/ConditionResults/ConditionResults';
import NoSearchResults from '../NoSearchResults/NoSearchResults';
import Container from '../../_ui/_blocks/Container/Container';
import Typography from '../../_ui/_blocks/Typography/Typography';
import SearchInput from '../SearchInput/SearchInput';
import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

import styles from './SearchOverlay.module.css';

/**
 * Search Overlay
 * This incorporates a few components and is displayed when either forced or when the search context
 * tells it to.
 * This displays a result set of treatments and conditions based upon a user's search term provided.
 * @param {*} props
 */
const SearchOverlay = ({ open = false }) => {
    const previousInput = useRef();

    const { searchTerm, treatments, conditions, showOverlay, setShowOverlay, setSearchTerm } = useSearchContext();

    const {
        data: popularTreatments,
    } = useSWR(
        'search-popular-treatments',
        async () => getPopularTreatments(),
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            shouldRetryOnError: false,
            revalidateOnMount: true,
            fallbackData: [],
        }
    );

    const [isGreaterThan] = useWindowSizeCheck(767);

    const handleKeyDown = useCallback(
        (event) => {
            if (event.keyCode === 27 && showOverlay) {
                setShowOverlay(false);
                setSearchTerm('');
            }
        },
        [setShowOverlay, setSearchTerm, showOverlay]
    );

    useOnNavigate(() => setShowOverlay(false));

    useEffect(() => {
        if (showOverlay) {
            document.addEventListener('keydown', handleKeyDown);

            // Disable body scroll.
            document.body.style.overflow = 'hidden';

            const overlayInput = document.querySelector('._js-overlay-search input[type=search]');
            if (overlayInput?.focus) {
            overlayInput.focus();
            // Sets the cursor to the end of the input.
            overlayInput.setSelectionRange(overlayInput.value.length, overlayInput.value.length);
            }

            if (!isGreaterThan) {
                scrollToTop();
            }
        } else {
            // Enable body scroll.
            document.body.style.removeProperty('overflow');

            // If current focus element is an input before overlay opens.
            if (document.activeElement.nodeName === 'INPUT') {
                // Un-focus currently selected input.
                document.activeElement.blur();

                // Reset focus back to previously selected input.
                if (previousInput.current) {
                    previousInput.current.focus();
                    previousInput.current = undefined;
                }
            }

            // On unmount, remove document esc key event listener.
            return () => {
                document.removeEventListener('keydown', handleKeyDown);
            };
        }
    }, [showOverlay, handleKeyDown]);

    // On touch, if the input is focused on, un-focus so that the keyboard closes. An open keyboard in Safari
    // stops overflow hidden working on the body.
    useEffect(() => {
        // If server side or the overlay is not open.
        if (typeof window === 'undefined' || !showOverlay) {
            return;
        }

        // Get the active element, if it's not an input, abort.
        const activeElement = document.activeElement;
        if (activeElement?.tagName !== 'INPUT') {
            return;
        }

        // On scroll, if anything that's not the input is touched, un-focus from the input.
        const blurInputOnScroll = (e) => {
            if (e.target?.tagName !== 'INPUT') {
                activeElement.blur();
            }
        };

        document.addEventListener('touchstart', blurInputOnScroll);

        return () => {
            document.removeEventListener('touchstart', blurInputOnScroll);
        }
    }, [showOverlay]);

    return (
        <CSSTransition
            className={styles.overlay}
            classNames={{
                enterActive: styles.fadeIn,
                enterDone: styles.fadeIn,
                exit: styles.fadeOut,
            }}
            timeout={{ enter: 500, exit: 300 }}
            in={open || showOverlay}
            unmountOnExit
        >
            <div data-testid="search-overlay">
                <Container
                    container="xlarge"
                    gutter="small"
                    className="space-y-150 md:space-y-200 lg:space-y-300 md:container-gutters-medium lg:container-gutters-large"
                >
                    <SearchInput className="_js-overlay-search _js-draw-nav-search" clear/>
                    {/* added search term to stop the loading state from appearing if we're not actually searching for anything */}
                    {searchTerm.length > 0 && (
                        <Loading isLoading={!treatments}>
                            {treatments && treatments.length ? (
                            <Fragment>
                                <div className="grid grid-cols-1 gap-150 md:grid-cols-2">
                                <div className="mb-100 md:mb-0">
                                    <Typography as="h3" typeset="heading" size="200" className="mb-150">
                                        Treatments {treatments?.length ? `(${treatments?.length})` : null}
                                    </Typography>
                                    <TreatmentResults limit={6} treatments={treatments} />
                                </div>
                                <div>
                                    <Typography as="h3" typeset="heading" size="200" className="mb-150">
                                        Conditions {conditions?.length ? `(${conditions?.length})` : null}
                                    </Typography>
                                    {/* TODO: Update condition results with actual count added a generic number just for testing purposes */}
                                    <ConditionResults limit={2} conditions={conditions} count={20} />
                                </div>
                                </div>
                                <div className="text-center">
                                <ButtonLink
                                    href={`/search-results?searchTerm=${searchTerm}`}
                                    title="View all search results"
                                    className="block"
                                >
                                    View all results
                                </ButtonLink>
                                </div>
                            </Fragment>
                            ) : (
                            <NoSearchResults
                                searchTerm={searchTerm}
                                popularTreatments={popularTreatments}
                            />
                            )}
                        </Loading>
                    )}
                </Container>
            </div>
        </CSSTransition>
    );
};

export default SearchOverlay;
