'use client';

import React from 'react';

import { useSidebarNavigationUpdaterContext } from '../../../data/context/sidebarNavigationContext';
import { emitNavigationOpenEvent } from '../../../hooks/useNavigationCloseHook';

import Button from '../../_ui/_blocks/Buttons/Button/Button';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from '../DrawerNavigation.module.css';

const MenuButton = () => {
    const setSidebarNavIsOpen = useSidebarNavigationUpdaterContext();

    const openMobileMenu = () => {
        emitNavigationOpenEvent('mobile');
        setSidebarNavIsOpen(true);
    };

    return (
        <Button
            variant="none"
            size="none"
            className={styles.button}
            aria-label="Open the main menu"
            title="Open the main menu"
            onClick={openMobileMenu}
        >
            Menu
            <Icon icon="menu-light" alt="menu icon" />
        </Button>
    );
};

export default MenuButton;
