import React from 'react';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import TreatmentCardGrid from '../TreatmentCardGrid/TreatmentCardGrid';
import useRecentlyViewedTreatments from './hooks/useRecentlyViewedTreatments';
import TreatmentCardGridSkeleton from '../TreatmentCardGrid/TreatmentCardGridSkeleton';

/**
 * Shows a grid of treatments the user has viewed previously if they are logged in.
 */
const RecentlyViewedTreatments = () => {
    const { treatments, isLoading } = useRecentlyViewedTreatments();

    // If there are no results.
    if (!isLoading && !treatments.length) return null;

    return (
        <section className="space-y-100">
            <Typography as="h4" typeset="subtitle" size="100" weight="600" className="text-center">
                Recently Viewed
            </Typography>
            {isLoading ? <TreatmentCardGridSkeleton /> : <TreatmentCardGrid treatments={treatments} />}
        </section>
    );
};

export default RecentlyViewedTreatments;
