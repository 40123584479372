import React from 'react';
import get from 'lodash.get';

import formatPrice from '../../../../helpers/formatPrice';

import ProductType from '../types/ProductType';
import Typography from '../../../_ui/_blocks/Typography/Typography';

import styles from './TreatmentCardPrice.module.css';

enum Prefix {
    SUPER = 'from',
    TIMED = 'each',
}

interface Props {
    type: ProductType;
    price: number;
}

const TreatmentCardPrice = ({ type, price }: Props) => {
    const prefix = get(Prefix, type);

    return (
        <div className={styles.container}>
            {prefix ? (
                <Typography as="div" size="070" color="quiet" className={styles.prefix}>
                    {prefix}{' '}
                </Typography>
            ) : null}
            <Typography as="span" typeset="emphasis" size="300" className={styles.price} lineHeight="none">
                {formatPrice(price)}
            </Typography>
        </div>
    );
};

export default TreatmentCardPrice;
