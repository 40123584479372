import dispatchGoogleAnalyticsEvent, { gaEvents } from '../../../../analytics/google/dispatchGoogleAnalyticsEvent';
import { AnalyticsCommand } from '../interfaces';

export interface FilterRemoveCommandProps {
    event: gaEvents;
    list: string;
    filterMenu: string;
    filterSelection: string;
}

export class FilterRemoveCommand implements AnalyticsCommand {
    execute({ event, list, filterMenu, filterSelection }: FilterRemoveCommandProps) {
        const data = {
            list,
            filter_menu: filterMenu,
            filter_selection: filterSelection,
        };
        dispatchGoogleAnalyticsEvent(event, data);
    }
}
