import React from 'react';

import { urlForImage } from '../../../../lib/sanity/image';

import Card from '../../../_ui/_blocks/Card/Card';
import Icon from '../../../_ui/_blocks/Icon/Icon';
import Typography from '../../../_ui/_blocks/Typography/Typography';
import ImageWithFallback from '../../../NextJs/ImageWithFallback/ImageWithFallback';
import Link from '../../../NextJs/Link/Link';

import styles from './TreatmentResults.module.css';

export interface TreatmentResultsProps {
    treatments:
        | {
              name: string;
              condition: string;
              pack_size: string;
              image_1: string;
              url: string;
              condition_url: string;
              parent_url: string | null;
          }[]
        | undefined;
    limit: number | undefined;
}

/**
 * Treatment Results
 * Does what it says on the tin, displays a list of results.
 */
const TreatmentResults = ({ treatments = undefined, limit = undefined }: TreatmentResultsProps) => (
    <div className="@container">
        <ul data-testid="treatment-search-results" className="space-y-100">
            {treatments &&
                treatments.slice(0, limit).map((treatment) => {
                    const {
                        name,
                        condition,
                        pack_size: packSize,
                        image_1: image,
                        url,
                        condition_url: conditionUrl,
                        parent_url: parentUrl,
                    } = treatment;

                    // TODO: Remove cdn.sanity.io check once algolia image URLs are updated.
                    const formattedImageSrc = image && image.includes('cdn.sanity.io')
                        ? urlForImage(image).width(64).height(64).fit('min').url()
                        : image;

                    /**
                     * - Creates the link to the treatment page.
                     * - If the treatment has a parent, it adds the default variant to select.
                     * - Need to add fbs=0 (filter by stock) url param so that if the user clicks a search
                     *   result, the default variant selected ignores stock (for treatment selector).
                     */
                    const treatmentUrl = parentUrl
                        ? `/${conditionUrl}/${parentUrl}?default=${url}&fbs=0`
                        : `/${conditionUrl}/${url}?default=${url}&fbs=0`;

                    return (
                        <Card as="li" padding="none" className={styles.card} key={name} variant="bordered" disableBoxShadow>
                            <Link
                                href={treatmentUrl}
                                className="flex gap-050 items-center p-075 w-full"
                                title={`Click to view ${name}`}
                                prefetch="hover"
                            >
                                <ImageWithFallback
                                    className={styles.image}
                                    src={formattedImageSrc}
                                    alt={name}
                                    fallback="images/the-independent-pharmacy-logo.svg"
                                    width={64}
                                    height={64}
                                />
                                <span>
                                    <Typography typeset="heading" size="090" className="block">
                                        {name}
                                    </Typography>
                                    <Typography size="080" color="quiet" className="block">
                                        {condition}
                                    </Typography>
                                    {packSize ? (
                                        <Typography size="080" color="quiet" className="block">
                                            {packSize}
                                        </Typography>
                                    ) : null}
                                </span>
                                <span className="ml-auto">
                                    <Icon icon="chevron-right-light" alt="chevron icon pointing right" size="small" />
                                </span>
                            </Link>
                        </Card>
                    );
                })}
        </ul>
    </div>
);

export default TreatmentResults;
