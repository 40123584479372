import dispatchGoogleAnalyticsEvent, { gaEvents } from '../../../../analytics/google/dispatchGoogleAnalyticsEvent';
import { ValueObjects } from '../../../helpers/ValueObjects';
import { AnalyticsCommand } from '../interfaces';
import createFilters from '../../../../../components/Treatment/TreatmentSortAndFilter/filterFactory/createFilters';

export interface ZeroResultsCommandProps {
    event: gaEvents;
    list: string;

    filters: ReturnType<typeof createFilters>;
}

export interface ZeroResultsGAProps {
    list: string;
    filter_query: string;
}

export class ZeroResultsCommand implements AnalyticsCommand {
    private vo: ValueObjects;

    constructor() {
        this.vo = new ValueObjects(null);
    }

    execute({ event, list, filters }: ZeroResultsCommandProps) {
        const filterValues = Array.from(filters.values());
        let prettyQuery = '';
        filterValues.forEach((filter) => {
            if (filter.keys.size) {
                if (prettyQuery.length) {
                    prettyQuery = `${prettyQuery},`;
                }
                if (filter.type === 'review') {
                    prettyQuery = `${prettyQuery}Review(${filter.formatKeysForUrl()})`;
                } else if (filter.type === 'productType') {
                    prettyQuery = `${prettyQuery}${filter.formatKeysForUrl() === 'true' ? 'Over the counter' : 'Not over the counter'}`;
                } else {
                    prettyQuery = `${prettyQuery}${filter.formatKeysForUrl()}`;
                }
            }
        });
        const data = {
            list,
            filter_query: prettyQuery,
        };
        if (this.vo.isDifferent(data)) {
            dispatchGoogleAnalyticsEvent(event, data);
        }
    }
}
