'use client';

import React from 'react';

import Icon from '../../_ui/_blocks/Icon/Icon';
import { useAccountNavigationContext } from '../../../data/context/accountContext/accountNavigationContext';
import { useAuthContext } from '../../../data/context/authContext';
import { emitNavigationOpenEvent } from '../../../hooks/useNavigationCloseHook';

import ButtonLink from '../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import Button from '../../_ui/_blocks/Buttons/Button/Button';

import styles from '../DrawerNavigation.module.css';

interface Props {
    isReturningCustomer: boolean;
}

export const SignInButton = ({ isReturningCustomer }: Props) => (
    <ButtonLink
        variant={isReturningCustomer ? 'primary' : 'none'}
        size="none"
        className={styles.button}
        aria-label="Your Independent Pharmacy account"
        title="Your Independent Pharmacy account"
        href="/login"
    >
        Sign In
        <Icon icon="profile-light" alt="profile icon" />
    </ButtonLink>
);

export const AccountButton = () => {
    const { isLoggedIn, authedBefore } = useAuthContext();
    const { setAccountNavIsOpen } = useAccountNavigationContext();

    const openAccountSidebar = () => {
        emitNavigationOpenEvent('account');
        setAccountNavIsOpen(true);
    };

    if (isLoggedIn) {
        return (
            <Button
                variant="none"
                size="none"
                className={styles.button}
                title="Your Independent Pharmacy account"
                aria-label="Your Independent Pharmacy account"
                onClick={openAccountSidebar}
            >
                Account
                <Icon icon="profile-light" alt="profile icon" />
            </Button>
        );
    }

    return <SignInButton isReturningCustomer={authedBefore} />;
}
