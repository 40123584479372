export class ValueObjects {
    private parameters: Record<string, any> | string | null = null;

    constructor(parameters: Record<string, any> | string | null) {
        this.parameters = parameters;
    }

    isDifferent(value: any) {
        const different: boolean = JSON.stringify(value) !== JSON.stringify(this.parameters);
        this.parameters = value;
        return different;
    }
}
