/**
 * This class is used to track the treatments that have been viewed by a user.
 */
class TrackViewedTreatments {
    private isLocalStorageAvailable = typeof window !== 'undefined' && window.localStorage;
    private isLoggedIn = this.isLocalStorageAvailable ? !!localStorage.getItem('auth') : false;

    private viewedTreatmentIds: Array<number> = [];

    // Max number of viewed treatments to store.
    private maxViewedTreatments = 4;

    // Object containing local storage keys that need to be accessed.
    private localStorageKeys = {
        auth: 'auth',
        recentlyViewedTreatments: 'recently_viewed_treatments',
    };

    constructor() {
        if (this.isLocalStorageAvailable) {
            if (this.isLoggedIn) {
                // Get the viewed treatment IDs from local storage (or an empty array) and set the IDs in
                // this class.
                this.viewedTreatmentIds = JSON.parse(localStorage.getItem(this.localStorageKeys.recentlyViewedTreatments) || '[]');
            } else {
                this.clearViewedTreatmentIds();
            }
        }

        this.initAuthListener();
    }

    /**
     * If local storage is available, listen for changes to the auth token so that if a user logs out, viewed
     * treatment IDs are wiped.
     *
     * If theres no storage available, no user can be logged in anyway.
     */
    initAuthListener = () => {
        if (!this.isLocalStorageAvailable) {
            return;
        }

        // On storage change, check for stored auth token, if it's not there or is a falsy value, clear the
        // viewed treatment IDs.
        window.addEventListener('storage', () => {
            this.isLoggedIn = !!localStorage.getItem(this.localStorageKeys.auth);

            if (!this.isLoggedIn) {
                this.clearViewedTreatmentIds();
            }
        });
    };

    /**
     * Returns the stored treatment IDs that have been viewed.
     */
    getIds = () => this.viewedTreatmentIds;

    /**
     * Adds a treatment ID to the viewed treatments array.
     */
    addId = (id: number) => {
        if (!this.isLoggedIn) {
            return;
        }

        // If id is in array, remove it, then add it to the front of the array.
        this.viewedTreatmentIds = this.viewedTreatmentIds.includes(id)
            ? this.viewedTreatmentIds.filter((i) => i !== id)
            : this.viewedTreatmentIds;

        // Adds ID to the start of the array and trims the array to the max length.
        this.viewedTreatmentIds.unshift(id);
        this.viewedTreatmentIds = this.viewedTreatmentIds.slice(0, this.maxViewedTreatments);

        localStorage.setItem(this.localStorageKeys.recentlyViewedTreatments, JSON.stringify(this.viewedTreatmentIds));
    };

    /**
     * Clears the viewed treatment IDs from this class and local storage.
     */
    clearViewedTreatmentIds = () => {
        this.viewedTreatmentIds = [];
        if (this.isLocalStorageAvailable) {
            localStorage.removeItem(this.localStorageKeys.recentlyViewedTreatments);
        }
    };
}

export default new TrackViewedTreatments();
