import React from 'react';

import Typography from '../../_ui/_blocks/Typography/Typography';
import Icon from '../../_ui/_blocks/Icon/Icon';

import styles from './SearchEmpty.module.css';

interface Props {
    searchTerm: string;
}

const SearchEmpty = ({ searchTerm }: Props) => (
    <div className={styles.searchEmpty}>
        <Typography as="p" typeset="heading" size="100">
            Sorry, we couldn't find anything for "{searchTerm}", you might want to try:
        </Typography>
        <ul className="space-y-050">
            <li className={styles.linkItem}>
                <Icon icon="checkmark-thin-green" size="small" alt="green checkmark icon" />
                <Typography as="span" size="090">
                    Double checking your spelling
                </Typography>
            </li>
            <li className={styles.linkItem}>
                <Icon icon="checkmark-thin-green" size="small" alt="green checkmark icon" />
                <Typography as="span" size="090">
                    Try more generic term e.g. "Hair Loss" or "Pain Relief"
                </Typography>
            </li>
            <li className={styles.linkItem}>
                <Icon icon="checkmark-thin-green" size="small" alt="green checkmark icon" />
                <Typography as="span" size="090">
                    Avoid multiple word searches e.g. "Viagra" vs “Viagra branded medication"
                </Typography>
            </li>
        </ul>
    </div>
);

export default SearchEmpty;
