
// import 'server-only';

import defaultHeaders from './config/defaultHeaders';

export const fetcher = async (url: string, options?: RequestInit) => fetch(url, {
    ...options,
    headers: {
        ...defaultHeaders,
        ...(options?.headers ? options.headers : {}),
    }
})
