import dispatchGoogleAnalyticsEvent, { gaEvents } from '../../../../analytics/google/dispatchGoogleAnalyticsEvent';
import { AnalyticsCommand } from '../interfaces';

export interface ProductClickCommandProps {
    event: gaEvents;
    ecommerce: {
        click: {
            actionField: {
                list: string; // URL path e.g. '/acid-reflux-heartburn'
            };
            products: [
                {
                    name: string; // e.g. 'Famotidine 20mg Tablets';
                    id: number | undefined; // e.g. 6347;
                    category: string; // e.g. 'Acid Reflux & Heartburn';
                    variant: string; // e.g. 'Famotidine 20mg Tablets';
                    dimension2: string; // e.g. 'Not Logged in';
                    dimension3: string; // e.g. 'ODONLY';
                }
            ];
        };
    };
}

export class ProductClickCommand implements AnalyticsCommand {
    execute({ event, ecommerce }: ProductClickCommandProps) {
        const data = {
            ecommerce,
        };
        dispatchGoogleAnalyticsEvent(event, data);
    }
}
