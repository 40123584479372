import React from 'react';

import { RedisTreatment } from '../../../../lib/redis/types/RedisTreatment';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import TreatmentCardGrid from '../TreatmentCardGrid/TreatmentCardGrid';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';

interface Props {
    treatments: RedisTreatment[];
}

const PopularTreatments = ({ treatments }: Props) => (
    <section className="space-y-100">
        <Typography as="h4" typeset="subtitle" size="100" weight="600" className="text-center">
            Popular Treatments
        </Typography>
        <TreatmentCardGrid treatments={treatments} />
        <div className="text-center">
            <ButtonLink href="/treatments" title="View all treatments">View all treatments</ButtonLink>
        </div>
    </section>
);

export default PopularTreatments;
