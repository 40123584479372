import React from 'react';

import TreatmentCardSkeleton from '../../../Treatment/TreatmentCard/TreatmentCardSkeloton';

const TreatmentCardGridSkeleton = () => (
    <div className="@container">
        <ul className="grid grid-cols-2 gap-075 @[540px]:grid-cols-3 @[540px]:gap-150 @[960px]:grid-cols-4">
            <TreatmentCardSkeleton />
            <TreatmentCardSkeleton />
            <TreatmentCardSkeleton className="hidden @[540px]:block" />
            <TreatmentCardSkeleton className="hidden @[720px]:block" />
        </ul>
    </div>
);

export default TreatmentCardGridSkeleton;
