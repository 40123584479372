import get from 'lodash.get';
import useSWR from 'swr';

import { useRef } from 'react';
import patientsService from '../../../../../data/services/patients.service';
import { useAuthContext } from '../../../../../data/context/authContext';
import { RedisTreatment } from '../../../../../lib/redis/types/RedisTreatment';
import { fetcher } from '../../../../../lib/fetch/fetcher';


const useTreatmentsToOrderAgain = () => {
    const { isLoggedIn, patientUuid, storybook } = useAuthContext();
    const shouldShowMore = useRef(false);

    const request = isLoggedIn ? async () => {
        // Only import mock data if we are using this hook in Storybook.
        if (storybook) {
            // TODO: Reimplement this later, data needs updating to match new RedisTreatment interface.
            // return (await import('../../mock/TreatmentSuggestions.mockdata.json')).default;
            return [];
        }

        // Get users recent orders, page 1, per page of 4 as the limit to display is 5.
        const previouslyOrderedTreatments = await patientsService.getPreviousTreatments(patientUuid, 1, 5);
        const previouslyOrderedTreatmentIds = ((get(previouslyOrderedTreatments, 'data.data') || []) as any[]).map(
            (item) => item.reference
        );

        const response = await fetcher(`/api/treatment-cache?ids=${previouslyOrderedTreatmentIds.join(',')}`, {
            method: 'GET',
            cache: 'no-cache',
        });
        const treatmentData = (await response.json()).data.treatments;
        shouldShowMore.current = treatmentData.length > 4;
        return treatmentData.slice(0, 4) as RedisTreatment[];
    } : null;

    const { data, isLoading } = useSWR<RedisTreatment[]>(
        'useTreatmentsToOrderAgain',
        request,
        {
            revalidateOnFocus: false,
            revalidateOnReconnect: false,
            refreshWhenHidden: false,
            refreshWhenOffline: false,
            revalidateIfStale: false,
            revalidateOnMount: true,
        }
    );

    // We only want to show 4 treatments.
    const treatments = data || [];

    return {
        isLoading,
        treatments,
        showViewMore: shouldShowMore.current,
    };
};

export default useTreatmentsToOrderAgain;
