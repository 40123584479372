import React from 'react';
import Card from '../../_ui/_blocks/Card/Card';

interface Props {
    className?: string;
}

const TreatmentCardSkeleton = ({ className }: Props) => (
    <Card className={`space-y-100 ${className}`} padding="small">
        <div className="skeleton-animation w-[6.75rem] h-[6.75rem] mx-auto" data-testid="skeleton" />
        <div className="space-y-050">
            <div className="skeleton-animation h-[1.2rem] max-w-[40%]" />
            <div className="skeleton-animation h-[0.8rem] max-w-[80%]" />
            <div className="skeleton-animation h-[0.8rem] max-w-[80%]" />
            <div className="skeleton-animation h-[0.8rem] max-w-[80%]" />
        </div>
        <div className="flex justify-between gap-200">
            <div className="w-1/2 space-y-025">
                <div className="skeleton-animation h-[0.8rem]" />
                <div className="skeleton-animation h-[0.8rem]" />
            </div>
            <div className="w-1/2 space-y-025">
                <div className="ml-auto skeleton-animation h-[0.8rem] max-w-[4rem]" />
                <div className="ml-auto skeleton-animation h-[0.8rem] max-w-[4rem]" />
            </div>
        </div>
        <div className="skeleton-animation h-[1.2rem] max-w-[5.75rem] block mx-auto" />
    </Card>
);

export default TreatmentCardSkeleton;
