export const searchPopularSearches = [
    {
        name: 'Canesten',
        term: 'canesten',
    },
    {
        name: 'Evorel',
        term: 'evorel',
    },
    {
        name: 'Solpadeine',
        term: 'solpadeine',
    },
    {
        name: 'Syndol',
        term: 'syndol',
    },
    {
        name: 'Acne',
        term: 'acne',
    },
    {
        name: 'Thrush',
        term: 'thrush',
    },
];
