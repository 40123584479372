import React from 'react';

import Typography from '../../../_ui/_blocks/Typography/Typography';
import TreatmentCardGrid from '../TreatmentCardGrid/TreatmentCardGrid';
import useTreatmentsToOrderAgain from './hooks/useTreatmentsToOrderAgain';
import ButtonLink from '../../../_ui/_blocks/Buttons/ButtonLink/ButtonLink';
import TreatmentCardGridSkeleton from '../TreatmentCardGrid/TreatmentCardGridSkeleton';

import styles from './OrderAgainTreatments.module.css';

/**
 * Shows a grid of treatments the user has ordered previously if they are logged in.
 */
const OrderAgainTreatments = () => {
    const { isLoading, treatments, showViewMore } = useTreatmentsToOrderAgain();

    if (!isLoading && !treatments.length) {
        return null;
    }

    return (
        <section className="space-y-100">
            <Typography as="h4" typeset="subtitle" size="100" weight="600" className="text-center">
                Order Again
            </Typography>
            {isLoading ? <TreatmentCardGridSkeleton /> : <TreatmentCardGrid treatments={treatments} className={styles.grid} />}
            {showViewMore ? (
                <div className="text-center">
                    <ButtonLink href="/account/orders/treatment-reorders">
                        View all treatments
                    </ButtonLink>
                </div>
            ) : null}
        </section>
    );
};

export default OrderAgainTreatments;
